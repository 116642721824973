import { GridRowParams } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import axios from 'axios'
import { IKeyCode, getKeyCodes, resetKeys, updateKeyCode } from '../redux/KeyCodes'
import KeyAdminToolView from '../components/KeyCodes/KeyAdminToolView'
import KeyAdminDialog from '../components/KeyCodes/KeyAdminDialog'
import { BACKEND_URL } from '../constants'
import { toast } from 'react-toastify'
import { toogleLoadingBackdrop } from '../redux/Settings'
import KeyAdminLogin from '../components/KeyCodes/KeyAdminLogin'
import KeyAdminAddKey from '../components/KeyCodes/KeyAdminAddKey'
import { Stack } from '@mui/material'
import RefreshButton from '../components/KeyCodes/RefreshButton'

export default function KeyAdminTool() {
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [inputs, setInputs] = useState<IKeyCode | null>(null)

  const dispatch = useDispatch()
  const isAdmin = useSelector((state: RootState) => state.keyCodesReducer.isAdmin)
  const keyCodes = useSelector((state: RootState) => state.keyCodesReducer.keyCodes)

  const handleClickOpen = () => {
    setOpenDialog(true)
  }
  const handleClose = () => {
    setOpenDialog(false)
    setInputs(null)
  }

  function onRowSelection(params: GridRowParams) {
    handleClickOpen()
    setInputs(params.row)
  }

  async function handlerOnSubmit(event: React.FormEvent) {
    event.preventDefault()
    if (inputs === null) return
    try {
      dispatch(toogleLoadingBackdrop())
      const response = await axios.put(
        `${BACKEND_URL}/key-codes`,
        {
          KeyId: inputs.KeyId,
          KeyProfileId: inputs.KeyProfileId,
          UserTypeId: inputs.UserTypeId,
          ExpireDateLibrary: inputs.ExpireDateLibrary,
          KeyEnabled: inputs.KeyEnabled ? 1 : 0,
          ExportDesignBonus: Number(inputs.ExportDesignBonus),
          CompId1: inputs.CompId1
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_current_stage === 'local' ? null : 'allow'
          }
        }
      )
      toast.success('Register updated')
      dispatch(updateKeyCode(response.data))
      handleClose()
    } catch (error: any) {
      toast.error('Something wrong happend, try again later')
      console.error(error?.message)
    }
    dispatch(toogleLoadingBackdrop())
  }

  const requestKeys = async () => {
    setLoading(true)
    try {
      dispatch(resetKeys())
      let areMore = true
      const limit = 4000
      let offset = 0
      while (areMore === true) {
        const response = await axios.get(`${BACKEND_URL}/key-codes`, {
          params: { limit, offset },
          headers: {
            Authorization: process.env.REACT_APP_current_stage === 'local' ? null : 'allow'
          }
        })
        dispatch(getKeyCodes(response.data))
        if (response?.data.length !== limit) {
          areMore = false
        } else {
          offset += limit
        }
      }
    } catch (error: any) {
      console.error(error.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (keyCodes.length === 0) {
      requestKeys()
    }
  }, [])

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} mb={2} gap={2}>
        <RefreshButton requestKeys={requestKeys} loading={loading} />
        {isAdmin && <KeyAdminAddKey />}
        <KeyAdminLogin />
      </Stack>
      <KeyAdminToolView loading={loading} keyCodes={keyCodes} onRowSelection={onRowSelection} />
      <KeyAdminDialog
        openDialog={openDialog}
        handleClose={handleClose}
        handlerOnSubmit={handlerOnSubmit}
        setInputs={setInputs}
        inputs={inputs}
      />
    </>
  )
}
