import React from 'react'
import { renderEnum } from '../../utils/keyCodes'

interface IStatus {
  field: string
  value: Object | null
}

export default function Status(props: IStatus) {
  const { field, value } = props
  if (value === null) {
    // Open
    return <span style={{ color: '#00d900' }}>{renderEnum(field, 1)}</span>
  } else {
    // Locked
    return <span style={{ color: '#ff2929' }}>{renderEnum(field, 0)}</span>
  }
}
