import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import {
  Stack,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
  DialogContentText,
  IconButton,
  Tooltip
} from '@mui/material'
import { Add, DeleteOutline } from '@mui/icons-material'
import {
  CREATE_USER_WITH_ROLE,
  DELETE_USER_WITH_ROLE,
  GET_GEMS_USER_ROLES,
  IS_VALID_GEMS_USER
} from '../graphql/queries/gemsQueries'
import SearchIcon from '@mui/icons-material/SearchTwoTone'
import { RootState } from '../store'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGrid, GridColDef, GridOverlay, GridRowModel, GridToolbar } from '@mui/x-data-grid'
import { toast } from 'react-toastify'
import { gemsUserGroups } from '../constants'

const ROLES = [
  gemsUserGroups.ADMIN,
  gemsUserGroups.OPS,
  gemsUserGroups.DEV,
  gemsUserGroups.SUPPORT,
  gemsUserGroups.SUPPORTREADONLY
]

// GQL Query Response Interface
interface IListGemsUsersRolesResults {
  userId: string
  username: string
  role: string
}

interface IAddGemsUserProps {
  dialogOpen: boolean
  handleAddUserDialog: (isOpen: boolean) => void
  handleAddUserWithRole: (username: string, role: string) => void
  refreshAllUsers: () => void
}

const AddGemsUserDialog: React.FC<IAddGemsUserProps> = ({
  dialogOpen,
  handleAddUserDialog,
  handleAddUserWithRole,
  refreshAllUsers
}) => {
  const [username, setUsername] = useState<string>('')
  const [role, setRole] = useState<string>('')
  const accessToken = useSelector((state: RootState) => state.authenticationReducer.accessToken)
  const [isValidGemsUsername, { data: isValidGemsUsernameData }] = useLazyQuery(IS_VALID_GEMS_USER, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  })

  const handleRoleValueChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string)
  }

  const handleUsernameValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value.toLowerCase())
  }

  const closeDialog = () => {
    setUsername('')
    setRole('')
    handleAddUserDialog(false)
  }

  useEffect(() => {
    if (isValidGemsUsernameData && isValidGemsUsernameData.isValidStullerUser) {
      handleAddUserWithRole(username, role)
      refreshAllUsers()
      closeDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidGemsUsernameData])

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Add New GEMS User</DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <Stack direction='row' gap={2} mt={1}>
          <TextField fullWidth id='username' label='Username' value={username} onChange={handleUsernameValueChange} />
          <FormControl fullWidth>
            <InputLabel id='role-group-label'>Role Group</InputLabel>
            <Select variant='outlined' label='User Role' value={role} onChange={handleRoleValueChange}>
              {ROLES.map((userRole) => (
                <MenuItem key={userRole} value={userRole}>
                  {userRole}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          onClick={() => {
            closeDialog()
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={username === '' || role === ''}
          onClick={() => {
            // check to make sure the username is a valid stuller username
            isValidGemsUsername({ variables: { username } })
          }}
          autoFocus
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface IGemsUsersMenuProps {
  handleAddUserDialog: (value: boolean) => void
}

const GemsUsersMenu: FC<IGemsUsersMenuProps> = ({ handleAddUserDialog }) => (
  <Button
    sx={{ mb: 2 }}
    variant='contained'
    startIcon={<Add />}
    onClick={() => {
      handleAddUserDialog(true)
    }}
  >
    Add New User
  </Button>
)

interface IGemsUsersResults {
  usersRolesCollection: Array<IListGemsUsersRolesResults>
  loading: boolean
}

const GemsUsersResults: FC<IGemsUsersResults> = ({ usersRolesCollection, loading }) => {
  const [rows, setRows] = useState<GridRowModel[]>([])
  const accessToken = useSelector((state: RootState) => state.authenticationReducer.accessToken)
  const StyledGridOverlay = styled(GridOverlay)(() => ({
    flexDirection: 'column'
  }))

  // custom display when datagrid is empty
  const CustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <Stack alignItems='center'>
        <SearchIcon fontSize='large' color='primary' />
        <Typography variant='overline'>Search for a valid username to load results.</Typography>
      </Stack>
    </StyledGridOverlay>
  )

  const RenderRemoveUser = ({ item, index }: { item: { role: string; username: string }; index: number }) => {
    const [deleteProcessing, setDeleteProcessing] = useState<boolean>(false)
    const [removeUserWithRole] = useMutation(DELETE_USER_WITH_ROLE, {
      context: {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      },
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' })
      }
    })
    const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)
    const removeDialogOpen = () => {
      setOpenRemoveDialog(true)
    }

    const removeDialogClose = () => {
      setOpenRemoveDialog(false)
    }
    const handleOnRemoveButtonClick = () => {
      setDeleteProcessing(true)
      removeUserWithRole({
        variables: {
          role: item.role,
          username: item.username
        }
      }).then(() => {
        setDeleteProcessing(false)
        const rf = rows.filter((r) => r.id !== index)
        setRows(rf)
        console.log(rf, index)
      })
    }
    return (
      <>
        {deleteProcessing ? (
          <CircularProgress />
        ) : (
          <>
            <Tooltip title='Remove User'>
              <IconButton color='error' onClick={removeDialogOpen}>
                <DeleteOutline />
              </IconButton>
            </Tooltip>
            <Dialog
              open={openRemoveDialog}
              onClose={removeDialogClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>Remove GEMS User</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to remove
                  <Typography component='span' sx={{ color: 'primary.main' }}>
                    {item.username}
                  </Typography>
                  ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color='secondary'
                  onClick={() => {
                    removeDialogClose()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  color='error'
                  onClick={() => {
                    handleOnRemoveButtonClick()
                    removeDialogClose()
                  }}
                  autoFocus
                >
                  Remove
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    )
  }

  // Custom Grid Render Component for Roles
  const RenderRoleSelect = ({ role, username }: { role: string; username: string }) => {
    const [currentRole, setCurrentRole] = useState<gemsUserGroups>(role as gemsUserGroups)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [createUserWithRoleMutation] = useMutation(CREATE_USER_WITH_ROLE, {
      context: {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      },
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' })
      }
    })

    const [deleteUserWithRoleMutation] = useMutation(DELETE_USER_WITH_ROLE, {
      context: {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      },
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' })
      }
    })

    // eslint-disable-next-line max-len
    const createUserWithRole = (user: string, newRole: gemsUserGroups) =>
      createUserWithRoleMutation({ variables: { username: user, role: newRole } })
    // eslint-disable-next-line max-len
    const deleteUserWithRole = (user: string, oldRole: gemsUserGroups) =>
      deleteUserWithRoleMutation({ variables: { username: user, role: oldRole } })
    const handleChangeUserRoleGroup = (user: string, oldRole: gemsUserGroups, newRole: gemsUserGroups) => {
      deleteUserWithRole(user, oldRole)
        .then((deleteResponse) => {
          console.log(deleteResponse)
          createUserWithRole(user, newRole).then((createResponse) => {
            const { username: uid, role: setRole } = createResponse.data.createUserRole
            toast.success(`${uid} set to ${setRole}`)
            setCurrentRole(newRole)
            setLoading(false)
          })
        })
        .catch((err) => {
          console.error(err)
          setLoading(false)
        })
    }

    const handleValueChange = (event: SelectChangeEvent) => {
      setLoading(true)
      const newRole = event.target.value as gemsUserGroups
      handleChangeUserRoleGroup(username, currentRole, newRole)
    }

    return (
      <>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Select size='small' variant='outlined' value={currentRole} onChange={handleValueChange}>
            {ROLES.map((userRole) => (
              <MenuItem key={userRole} value={userRole}>
                {userRole}
              </MenuItem>
            ))}
          </Select>
        )}
      </>
    )
  }

  // define column names and field ids for mapping row data
  const columns: GridColDef[] = [
    {
      field: 'username',
      headerName: 'Username',
      width: 200
    },
    {
      field: 'role',
      headerName: 'Role Group',
      renderCell: (params) => RenderRoleSelect(params.value),
      width: 300
    },
    {
      field: 'remove',
      headerName: 'Remove User',
      renderCell: (params) => RenderRemoveUser(params.value),
      width: 150
    }
  ]

  // Parses the query collection into the datagrid
  const createRowData = (usersRoleCollection: Array<IListGemsUsersRolesResults>) => {
    setRows(
      usersRoleCollection.map((item, index) => ({
        id: index,
        username: item.username,
        role: item,
        remove: { item, index }
      }))
    )
  }

  // Load data into Datagrid
  useEffect(() => {
    createRowData(usersRolesCollection)
  }, [usersRolesCollection, setRows])

  return (
    <>
      <DataGrid
        loading={loading}
        rows={rows}
        columns={columns}
        autoHeight
        disableRowSelectionOnClick
        hideFooter
        disableColumnFilter
        slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'username', sort: 'asc' }]
          }
        }}
      />
    </>
  )
}

const ManageGemsUsersView = () => {
  const accessToken = useSelector((state: RootState) => state.authenticationReducer.accessToken)
  const username = useSelector((state: RootState) => state.authenticationReducer.rootStore.userStore.username)
  // const [usernameSearchValue, setUsernameSearchValue] = useState<string>('');
  const [userRolesCollection, setUserRolesCollection] = useState<Array<IListGemsUsersRolesResults>>([])
  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false)
  const [getGemsUserRoles, { data, loading }] = useLazyQuery(GET_GEMS_USER_ROLES, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    fetchPolicy: 'network-only',
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' })
    }
  })

  const [createUserWithRoleMutation] = useMutation(CREATE_USER_WITH_ROLE, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' })
    }
  })

  // eslint-disable-next-line max-len
  const createUserWithRole = (user: string, newRole: gemsUserGroups) =>
    createUserWithRoleMutation({ variables: { username: user, role: newRole } })

  // const handleSearchValueChange = (e: any) => {
  //   setUsernameSearchValue(e.target.value);
  //   setUserRolesCollection([]);
  // };

  const handleAddUserDialog = (isOpen: boolean) => {
    setAddUserDialogOpen(isOpen)
  }

  const handleAddUserWithRole = (userNameInput: string, role: string) => {
    createUserWithRole(userNameInput, role as gemsUserGroups)
      .then(() => {
        toast.success(`Created user ${userNameInput}`)
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  const FetchAllUsers = () => {
    getGemsUserRoles({ variables: { input: {} } })
  }

  useEffect(() => {
    if (data) {
      const response = data.listUserRoles.items as Array<IListGemsUsersRolesResults>
      setUserRolesCollection(response.filter((item: IListGemsUsersRolesResults) => item.username !== username))
    }
    // loads all users automatically
    if (userRolesCollection.length === 0 && !loading && !data) {
      FetchAllUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <AddGemsUserDialog
        dialogOpen={addUserDialogOpen}
        handleAddUserDialog={handleAddUserDialog}
        handleAddUserWithRole={handleAddUserWithRole}
        refreshAllUsers={FetchAllUsers}
      />
      <Grid container>
        <Grid item xs={12}>
          <GemsUsersMenu handleAddUserDialog={handleAddUserDialog} />
        </Grid>
        <Grid item xs={12}>
          <GemsUsersResults loading={loading} usersRolesCollection={userRolesCollection} />
        </Grid>
      </Grid>
    </>
  )
}

export default ManageGemsUsersView
