import { Box, Grid } from '@mui/material'
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid'
import { IKeyCode } from '../../redux/KeyCodes'
import { renderEnum } from '../../utils/keyCodes'
import Status from './Status'

const userColumns: GridColDef[] = [
  {
    field: 'KeyId',
    headerName: 'Key',
    width: 53
  },
  {
    field: 'CompId1',
    headerName: 'Status',
    renderCell: (row: GridRenderCellParams) => <Status field='CompId1' value={row.value} />,
    width: 60
  },
  {
    field: 'KeyEnabled',
    headerName: 'Enabled',
    type: 'boolean',
    width: 70
  },
  {
    field: 'UserTypeId',
    headerName: 'User Level',
    renderCell: (row: GridRenderCellParams) => renderEnum(row.field, row.value),
    width: 120
  },
  {
    field: 'KeyProfileId',
    headerName: 'Key Profile',
    renderCell: (row: GridRenderCellParams) => renderEnum(row.field, row.value),
    width: 200
  },
  {
    field: 'CompanyName',
    headerName: 'Company',
    width: 200
  },
  {
    field: 'GemvisionAccountNumber',
    headerName: 'GV Acct',
    width: 80
  },
  {
    field: 'StullerShipToAccountNo',
    headerName: 'ShipTo Acct',
    width: 130
  },
  {
    field: 'ExpireDateLibrary', // ExpireDateCounterSketch or ExpireDateLibrary
    headerName: 'Support Expires',
    width: 120
  },
  {
    field: 'PrimaryEmail',
    headerName: 'Email',
    width: 200
  },
  {
    field: 'KeyCountryCode',
    headerName: 'Country',
    width: 70
  },
  {
    field: 'codes',
    headerName: 'Serial Code',
    width: 205
  },
  {
    field: 'ApplicationStartupDate',
    headerName: 'Last Startup',
    renderCell: (row: GridRenderCellParams) => new Date(row.value).toLocaleDateString()
  }
]

interface IKeyAdminToolView {
  loading: boolean
  keyCodes: IKeyCode[]
  onRowSelection: GridEventListener<'rowDoubleClick'>
}

export default function KeyAdminToolView(props: IKeyAdminToolView) {
  const { loading, keyCodes, onRowSelection } = props
  return (
    <Grid container justifyContent='center' spacing={2}>
      <Grid item xs={12}>
        <Box height='calc(100vh - 200px)' width='100%'>
          <DataGrid
            loading={loading}
            rows={keyCodes}
            columns={userColumns}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true
              }
            }}
            onRowDoubleClick={onRowSelection}
            getRowId={(row) => row.KeyId}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
