import {
  Backdrop,
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography
} from '@mui/material'
import { DataGrid, GridColDef, GridOverlay, GridRowModel, GridToolbar } from '@mui/x-data-grid'
import { FC, useEffect, useState } from 'react'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop'
import ContactPage from '@mui/icons-material/ContactPage'
import { useSelector } from 'react-redux'
import {
  LIST_ACCOUNT_ENTITLEMENTS,
  LIST_ACCOUNT_ENTITLEMENTS_BY_EMAIL,
  LIST_GEMS_USERS_REPORT,
  OFFSET_CREATE_ENTITLEMENT
} from '../graphql/queries/accountQueries'
import { IAccountEntitlementsResult, IAppRecords, IGemsUserReport } from '../graphql/types/accountTypes'
import {
  applicationCollection,
  applicationCollectionData,
  convertAppId,
  convertShipToBillTo,
  IApplicationOwned,
  isAnEmail,
  isBuyingGroupBillto
} from '../data/Helpers'
import SearchBarComponent from '../components/SearchBarComponent'
import { exportExcel } from '../utils/exportExcel'
import { RootState } from '../store'
import { getColumns } from '../components/entitlements/EntitlementColumns'

interface IManageAccountEntitlementsViewProps {
  writeAccessRole: string[]
}

const excelColumns: string[] = [
  'AccountName',
  'AccountNumber',
  'Email',
  'PhoneNumber',
  'CompanyName',
  'Country',
  'State',
  'City',
  'AddressLine',
  'Zip',
  'MG2',
  'MG2Date',
  'MG3',
  'MG3Date',
  'MGMembership',
  'MGMembershipDate',
  'CS',
  'CSDate',
  'CSStreaming',
  'CSStreamingDate',
  'CSMembership',
  'CSMembershipDate'
]

const ManageAccountEntitlementsView: FC<IManageAccountEntitlementsViewProps> = ({ writeAccessRole }) => {
  const rolesStore = useSelector((state: RootState) => state.authenticationReducer.rootStore.rolesStore)
  const accessToken = useSelector((state: RootState) => state.authenticationReducer.accessToken)

  const [inputSearch, setInputSearch] = useState<string>('')
  const [billToAccount, setBillToAccount] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [rows, setRows] = useState<GridRowModel[]>([])
  const [readOnlyAccess, setReadOnlyAccess] = useState(true)
  const [applicationOwnedAmounts, setApplicationOwnedAmounts] = useState<IApplicationOwned>({})
  const [initialApplicationOwnedAmounts, setInitialApplicationOwnedAmounts] = useState<IApplicationOwned>({})
  const [applicationOwnedExpiration, setApplicationOwnedExpiration] = useState<IApplicationOwned>({})
  const [initialApplicationOwnedExpiration, setInitialApplicationOwnedExpiration] = useState<IApplicationOwned>({})

  const [queryListGemsUsersReport, { loading: listGemsUsersReportLoading, data: listGemsUsersReportData }] =
    useLazyQuery(LIST_GEMS_USERS_REPORT, {
      context: { headers: { Authorization: `Bearer ${accessToken}` } },
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' })
      },
      fetchPolicy: 'network-only'
    })

  const [queryAccountEntitlementsByAccount, { loading: byAccountLoading, data: byAccountData }] = useLazyQuery(
    LIST_ACCOUNT_ENTITLEMENTS,
    {
      context: { headers: { Authorization: `Bearer ${accessToken}` } },
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' })
      },
      fetchPolicy: 'network-only'
    }
  )

  const [queryAccountEntitlementsByEmail, { loading: byEmailLoading, data: byEmailData }] = useLazyQuery(
    LIST_ACCOUNT_ENTITLEMENTS_BY_EMAIL,
    {
      context: { headers: { Authorization: `Bearer ${accessToken}` } },
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' })
      },
      fetchPolicy: 'network-only'
    }
  )
  const [byEmailUsername, setByEmailUsername] = useState('')
  const [openSelectUser, setOpenSelectUser] = useState(false)

  const [mutateCreateOffsetEntitlement] = useMutation(OFFSET_CREATE_ENTITLEMENT, {
    context: { headers: { Authorization: `Bearer ${accessToken}` } },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' })
    },
    onCompleted: (resp: { offsetClaims: { appId: string; amount: string | number } }) => {
      const toastMessage = `${convertAppId(resp.offsetClaims.appId)} updated to ${resp.offsetClaims.amount}`
      toast.success(toastMessage, { theme: 'colored' })
    }
  })

  const columns = getColumns({
    readOnlyAccess,
    setApplicationOwnedAmounts,
    applicationCollectionData,
    setApplicationOwnedExpiration,
    applicationOwnedAmounts,
    initialApplicationOwnedAmounts,
    applicationOwnedExpiration,
    initialApplicationOwnedExpiration,
    loading
  })
  const userColumns: GridColDef[] = [
    {
      field: 'billTo',
      headerName: 'AccountId',
      width: 200
    },
    {
      field: 'username',
      headerName: 'Username',
      width: 200
    },
    {
      field: 'fullName',
      headerName: 'Name',
      width: 200,
      valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant='contained'
          onClick={() => {
            console.log('Hello', params)
            setByEmailUsername(params.row.username)
            setBillToAccount(params.row.billTo)
            setOpenSelectUser(false)
          }}
        >
          Select
        </Button>
      )
    }
  ]

  const clearEntitlementStateData = () => {
    setInitialApplicationOwnedAmounts({})
    setApplicationOwnedAmounts({})
    setInitialApplicationOwnedExpiration({})
    setApplicationOwnedExpiration({})
  }

  // Method called after saving to retake values and do the + - of licenses
  const refreshRowDataClean = () => {
    clearEntitlementStateData()
    if (isAnEmail(inputSearch)) {
      queryAccountEntitlementsByEmail({ variables: { email: inputSearch } })
    } else {
      queryAccountEntitlementsByAccount({ variables: { accountId: convertShipToBillTo(inputSearch) } })
    }
  }

  const handleSearchChange = (value: string) => {
    setInputSearch(value)
    setRows([])
    clearEntitlementStateData()
  }

  const getEntitlementsByAccountId = (accountId: string) => {
    if (isBuyingGroupBillto(accountId)) {
      toast.error(`Account ${accountId} is an buying group bill to. Ship to must be provided for buying groups.`, {
        theme: 'colored'
      })
      return
    }
    queryAccountEntitlementsByAccount({ variables: { accountId: convertShipToBillTo(accountId) } })
  }

  const getEntitlementsByEmail = (email: string) => {
    setByEmailUsername('')
    queryAccountEntitlementsByEmail({ variables: { email } })
  }

  // #region Search Button action
  const handleSearch = () => {
    setRows([])
    const trimmed = inputSearch.trim()
    // fudging this for now. the first number in {1,6} probably needs to be more than 1.
    // It used to be {6,}, which matches exactly 6 digits, but we found accounts that
    // have 5 digits before '-' (such as 67350-000000), so I relaxed the regex to at least 1,
    // but up to 6 digits.
    const accountIdRe = RegExp('[\\d]{1,6}-[\\d]{6,}$')
    if (isAnEmail(trimmed)) {
      setInputSearch(trimmed)
      getEntitlementsByEmail(trimmed)
    } else if (accountIdRe.test(trimmed)) {
      setInputSearch(trimmed)
      getEntitlementsByAccountId(trimmed)
    } else {
      toast.error('Invalid Ship To or Email format provided', { theme: 'colored' })
    }
  }
  // #endregion Search Button action

  // #region Save Changes
  const handleCreateEntitlementsUsingOffsets = async () => {
    try {
      setLoading(true)
      await Promise.all(
        applicationCollection.map((appId) => {
          const newAmount = isNaN(Number(applicationOwnedAmounts[appId])) ? 0 : Number(applicationOwnedAmounts[appId])
          const oldAmount = isNaN(Number(initialApplicationOwnedAmounts[appId]))
            ? 0
            : Number(initialApplicationOwnedAmounts[appId])

          let finalAmount = 0
          if (newAmount < oldAmount) {
            finalAmount = (oldAmount - newAmount) * -1
          } else if (newAmount > oldAmount) {
            finalAmount = newAmount - oldAmount
          }

          let newExpiration = 0
          let oldExpiration = 0
          if (applicationCollectionData[appId].isSubscription) {
            newExpiration = isNaN(Number(applicationOwnedExpiration[appId]))
              ? 0
              : Number(applicationOwnedExpiration[appId])
            oldExpiration = isNaN(Number(initialApplicationOwnedExpiration[appId]))
              ? 0
              : Number(initialApplicationOwnedExpiration[appId])
          }
          console.log(`appId: ${appId} final amount: ${finalAmount}`)
          if (finalAmount !== 0 || (newExpiration !== 0 && newExpiration !== oldExpiration)) {
            return mutateCreateOffsetEntitlement({
              variables: {
                accountID: convertShipToBillTo(billToAccount),
                appId,
                amount: finalAmount,
                expiry: Math.round(newExpiration)
              }
            })
          }
          return null
        })
      )
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }
  // #endregion Save Changes

  const createMissingRecords = (missingRecords: Array<string>): Array<IAppRecords> => {
    return missingRecords.map((item) => ({
      appId: item,
      amount: 0,
      accountID: convertShipToBillTo(inputSearch),
      creationDate: null,
      expirationDate: null
    }))
  }

  // Finds records that are missing from accounts
  const generateMissingRecordsIfAny = (ownedRecords: Array<string>): Array<IAppRecords> => {
    const missing = applicationCollection.filter((obj) => !ownedRecords.includes(obj))
    return createMissingRecords(missing)
  }

  const populateRowData = (queryObject: IAccountEntitlementsResult) => {
    if (queryObject.items.length === 0) {
      // toast.error('Account not found.', { theme: 'colored' });
      // return;
    }
    const missingAppRecordCollection = generateMissingRecordsIfAny(queryObject.items.map((item) => item.appId))
    let combinedMissingAndOwnedRecords: Array<IAppRecords> = [...missingAppRecordCollection, ...queryObject.items]
    combinedMissingAndOwnedRecords = combinedMissingAndOwnedRecords.filter((record) =>
      applicationCollection.includes(record.appId)
    )

    const newRows: GridRowModel[] = []
    // populate the initial amounts of each record.
    combinedMissingAndOwnedRecords.forEach((item) => {
      setInitialApplicationOwnedAmounts((prevState) => ({
        ...prevState,
        [item.appId]: Number(item.amount)
      }))
      // set this to initial amounts, on component load this should be
      // what they currently own
      setApplicationOwnedAmounts((prevState) => ({
        ...prevState,
        [item.appId]: Number(item.amount)
      }))

      const initialExpiration =
        !item.expirationDate || item.expirationDate === 0 ? Date.now() / 1000 : item.expirationDate

      setInitialApplicationOwnedExpiration((prevState) => ({
        ...prevState,
        [item.appId]: initialExpiration
      }))

      setApplicationOwnedExpiration((prevState) => ({
        ...prevState,
        [item.appId]: initialExpiration
      }))

      newRows.push({
        id: applicationCollectionData[item.appId].sortIndex,
        app_name: convertAppId(item.appId),
        offset_control: { amount: Number(item.amount), appId: item.appId },
        difference: { appId: item.appId },
        creation_date: { date: item.creationDate },
        expiration_date: { date: initialExpiration, appId: item.appId },
        key: item.appId
      })
    })
    setRows(newRows.sort((a, b) => a.id - b.id))
  }

  const StyledGridOverlay = styled(GridOverlay)(() => ({
    flexDirection: 'column'
  }))

  const CustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <Stack alignItems='center'>
        <ScreenSearchDesktopIcon fontSize='large' color='primary' />
        <Typography variant='overline'>Search for a valid Ship To account number to load results.</Typography>
      </Stack>
    </StyledGridOverlay>
  )

  useEffect(() => {
    // check user roles to see if they have read only access
    const roles = rolesStore
    for (let i = 0; i < roles.length; i++) {
      const userRole = roles[i]
      const found = writeAccessRole.findIndex((element) => element === userRole)
      if (found > -1) {
        console.log('write access found')
        setReadOnlyAccess(false)
        break
      }
    }
    if (!isAnEmail(inputSearch)) {
      if (byAccountData) {
        setBillToAccount(byAccountData.listAccountAppRecords.items[0].accountID)
        populateRowData(byAccountData.listAccountAppRecords)
      }
    } else if (isAnEmail(inputSearch)) {
      if (byEmailData && byEmailData.listAccountAppRecordsByEmail) {
        if (byEmailData.listAccountAppRecordsByEmail.length === 1) {
          setBillToAccount(byEmailData.listAccountAppRecordsByEmail[0].billTo)
          populateRowData(byEmailData.listAccountAppRecordsByEmail[0])
        } else if (byEmailUsername !== '') {
          populateRowData(
            byEmailData.listAccountAppRecordsByEmail.find((u: { username: string }) => u.username === byEmailUsername)
          )
        } else if (!openSelectUser) {
          setOpenSelectUser(true)
        }
      }
    }
    if (byAccountLoading || byEmailLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byAccountData, byEmailData, byAccountLoading, byEmailLoading, byEmailUsername])

  useEffect(() => {
    if (listGemsUsersReportData) {
      const data: [IGemsUserReport] = listGemsUsersReportData.listGemsUsersReport
      exportExcel(data, excelColumns)
    }
  }, [listGemsUsersReportData])

  return (
    <>
      <Backdrop open={listGemsUsersReportLoading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress />
      </Backdrop>
      <Grid container justifyContent='center' spacing={2}>
        <Grid item xs={12}>
          <Stack direction='row' gap={1} justifyContent='stretch' alignItems='center'>
            <SearchBarComponent
              handleSearch={handleSearch}
              handleSearchChange={handleSearchChange}
              searchTextValue={inputSearch}
              placeHolderSearchText='Ship-To Account Number or Email'
            />
            <Tooltip title='Search for Ship-To Account Number (141833-000001) or for email (email@email.com)'>
              <Badge badgeContent='?' color='info' sx={{ right: 8, top: -26 }} />
            </Tooltip>
            <Tooltip title='Download Users Report'>
              <IconButton onClick={() => queryListGemsUsersReport()}>
                <ContactPage />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            loading={loading}
            rows={rows}
            columns={columns}
            autoHeight
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
            hideFooter
            disableColumnFilter
          />
        </Grid>
      </Grid>
      <Stack mt={2} alignItems='flex-end'>
        <Button
          variant='contained'
          color='primary'
          disabled={readOnlyAccess || rows.length === 0}
          onClick={async (e: React.MouseEvent<HTMLElement>) => {
            try {
              e.preventDefault()
              await handleCreateEntitlementsUsingOffsets()
              refreshRowDataClean()
            } catch (err) {
              console.error(err)
            }
          }}
        >
          Save Changes
        </Button>
      </Stack>
      <Dialog
        open={openSelectUser && byEmailUsername === '' && byEmailData && !!byEmailData.listAccountAppRecordsByEmail}
        onClose={() => setOpenSelectUser(false)}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minWidth: 'fit-content'
          }
        }}
      >
        <DialogTitle>Select user</DialogTitle>
        <DialogContent>
          <DataGrid
            style={{ minWidth: '700px' }}
            slots={{ noRowsOverlay: CustomNoRowsOverlay }}
            getRowId={(row) => row.username}
            rows={byEmailData?.listAccountAppRecordsByEmail}
            columns={userColumns}
            autoHeight
            disableRowSelectionOnClick
            hideFooter
            disableColumnSelector
            disableColumnFilter
            disableColumnMenu
          />
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={() => setOpenSelectUser(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ManageAccountEntitlementsView
