import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import React from 'react'
import { IKeyCode } from '../../redux/KeyCodes'
import { TextCopied, keyProfileEnum, userTypeEnum } from '../../constants'
import { Stack } from '@mui/system'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import Status from './Status'

interface IKeyAdminDialog {
  openDialog: boolean
  handleClose: () => void
  handlerOnSubmit: React.FormEventHandler<HTMLFormElement>
  setInputs: React.Dispatch<React.SetStateAction<IKeyCode | null>>
  inputs: IKeyCode | null
}

interface IUnlockButton {
  status: Buffer | null
  setInputs: React.Dispatch<React.SetStateAction<IKeyCode | null>>
}

// TODO: This button is not working yet
const UnlockButton = (props: IUnlockButton) => {
  const { status, setInputs } = props
  if (status !== null) {
    return (
      <Button
        color={'secondary'}
        onClick={() => setInputs((state: IKeyCode | null) => (state === null ? null : { ...state, CompId1: null }))}
      >
        Unlock
      </Button>
    )
  }
}

export default function KeyAdminDialog(props: IKeyAdminDialog) {
  const { openDialog, handleClose, handlerOnSubmit, setInputs, inputs } = props
  const [open, setOpen] = React.useState(false)
  const isAdmin = useSelector((state: RootState) => state.keyCodesReducer.isAdmin)

  function handleTooltipClose() {
    setOpen(false)
  }

  if (inputs === null) return null
  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth>
      <DialogTitle>Subscribe</DialogTitle>
      <form onSubmit={handlerOnSubmit}>
        <DialogContent
          sx={{ maxWidth: 1800, display: 'grid', gridTemplateColumns: { xs: '1fr', md: 'repeat(2,1fr)' }, gap: 4 }}
        >
          <Stack gap={1}>
            <Typography>
              Key: <span style={{ opacity: 0.7 }}>{inputs['KeyId'] ?? ''}</span>
            </Typography>
            <Typography>
              Company Name: <span style={{ opacity: 0.7 }}>{inputs['CompanyName'] ?? ''}</span>
            </Typography>
            <Typography>
              Name: <span style={{ opacity: 0.7 }}>{inputs['Name'] ?? ''}</span>
            </Typography>
            <Typography>
              Email: <span style={{ opacity: 0.7 }}>{inputs['PrimaryEmail'] ?? ''}</span>
            </Typography>
            <Typography>
              Gemvision Account Number: <span style={{ opacity: 0.7 }}>{inputs['GemvisionAccountNumber'] ?? ''}</span>
            </Typography>
            <Typography>
              Stuller Ship To Account No.:{' '}
              <span style={{ opacity: 0.7 }}>{inputs['StullerShipToAccountNo'] ?? ''}</span>
            </Typography>
            <Typography>
              Address: <span style={{ opacity: 0.7 }}>{inputs['StullerShipToAddress1'] ?? ''}</span>
            </Typography>
            <Typography>
              City: <span style={{ opacity: 0.7 }}>{inputs['StullerShipToCity'] ?? ''}</span>
            </Typography>
            <Typography>
              State: <span style={{ opacity: 0.7 }}>{inputs['StullerShipToState'] ?? ''}</span>
            </Typography>
            <Typography>
              Country Code: <span style={{ opacity: 0.7 }}>{inputs['KeyCountryCode'] ?? ''}</span>
            </Typography>
            <Typography>
              Zip: <span style={{ opacity: 0.7 }}>{inputs['StullerShipToZip'] ?? ''}</span>
            </Typography>
            <Typography>
              Last Startup:{' '}
              <span style={{ opacity: 0.7 }}>
                {inputs['ApplicationStartupDate'] && new Date(inputs['ApplicationStartupDate']).toLocaleString()}
              </span>
            </Typography>
            <Typography>
              Status:{' '}
              <span style={{ opacity: 0.7 }}>
                <Status field='CompId1' value={inputs['CompId1']} />
              </span>
            </Typography>
          </Stack>
          <Stack>
            <Stack direction={'row'} alignItems={'center'}>
              <TextField
                color='secondary'
                margin='dense'
                id={'Serial Code'}
                label={'Serial Code'}
                variant='outlined'
                value={inputs['codes'] ?? ''}
                fullWidth
                disabled
              />
              {inputs['codes'] !== null && (
                <Tooltip
                  title={TextCopied}
                  open={open}
                  onClose={handleTooltipClose}
                  leaveDelay={1000}
                  placement='top'
                  arrow
                >
                  <IconButton
                    sx={{ ml: 1 }}
                    onClick={() => {
                      if (inputs['codes'] !== null) {
                        setOpen(true)
                        navigator.clipboard.writeText(inputs['codes'])
                      }
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            <TextField
              label={'Expire Date Library'}
              value={inputs['ExpireDateLibrary'] ?? null}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setInputs((state: IKeyCode | null) =>
                  state === null ? null : { ...state, ExpireDateLibrary: event.target.value }
                )
              }
              type='date'
              color='secondary'
              margin='dense'
              name={'ExpireDateLibrary'}
              id={'Expire Date Library'}
              variant='outlined'
              fullWidth
            />
            <FormControl fullWidth margin='dense'>
              <InputLabel id='demo-simple-select-label'>Key Profile Id</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='KeyProfileId'
                value={inputs['KeyProfileId'] ?? ''}
                label='Key Profile Id'
                disabled={!isAdmin}
                onChange={(event: SelectChangeEvent<number>) =>
                  setInputs((state: IKeyCode | null) =>
                    state === null ? null : { ...state, KeyProfileId: Number(event.target.value) }
                  )
                }
              >
                {Object.entries(keyProfileEnum).map((item) => (
                  <MenuItem key={item[0]} value={item[0]}>
                    {item[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin='dense'>
              <InputLabel id='demo-simple-select-label'>User Type Id</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='UserTypeId'
                value={inputs['UserTypeId']}
                label='User Type Id'
                disabled={!isAdmin}
                onChange={(event: SelectChangeEvent<number>) =>
                  setInputs((state: IKeyCode | null) =>
                    state === null ? null : { ...state, UserTypeId: Number(event.target.value) }
                  )
                }
              >
                {Object.entries(userTypeEnum).map((item) => (
                  <MenuItem key={item[0]} value={item[0]}>
                    {item[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              name={'KeyEnabled'}
              control={
                <Checkbox
                  disabled={!isAdmin}
                  checked={Boolean(inputs['KeyEnabled'])}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setInputs((state: IKeyCode | null) =>
                      state === null ? null : { ...state, KeyEnabled: Number(event.target.checked) }
                    )
                  }
                />
              }
              label='Key Enabled'
            />
            <TextField
              color='secondary'
              type='number'
              margin='dense'
              id={'ExportDesignBonus'}
              name={'ExportDesignBonus'}
              label={'Export Design Bonus'}
              variant='outlined'
              value={inputs['ExportDesignBonus'] ?? ''}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setInputs((state: IKeyCode | null) =>
                  state === null ? null : { ...state, ExportDesignBonus: Number(event.target.value) }
                )
              }
            />
            <FormControlLabel
              disabled
              control={<Checkbox disabled checked={Boolean(inputs['hasExportFeature'])} />}
              label='Has Export Feature'
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <UnlockButton setInputs={setInputs} status={inputs['CompId1']} />
          <Button color={'secondary'} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' type='submit'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
